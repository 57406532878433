import React, { useEffect, useState } from 'react';

const WordPressPage = () => {
  const [pageContent, setPageContent] = useState('');
  const [loading, setLoading] = useState(true);
  
  // Helper to inject external scripts
  const injectScript = (src) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
  };

  // Helper to inject inline scripts
  const injectInlineScript = (content) => {
    const script = document.createElement('script');
    script.textContent = content;
    document.body.appendChild(script);
  };

  useEffect(() => {
    fetch('https://3.13.169.166')
      .then((response) => response.text())
      .then((data) => {
        setPageContent(data);
        setLoading(false);
        debugger;
        // Parse the HTML content to get script tags
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(data, 'text/html');

        // Extract and inject external script files
        const externalScripts = htmlDoc.querySelectorAll('script[src]');
        externalScripts.forEach((scriptTag) => {
          injectScript(scriptTag.src);
        });

        // Extract and inject inline scripts
        const inlineScripts = htmlDoc.querySelectorAll('script:not([src])');
        inlineScripts.forEach((scriptTag) => {
          injectInlineScript(scriptTag.innerHTML);
        });
      })
      .catch((error) => {
        console.error('Error fetching the WordPress page:', error);
        setLoading(false);
      });
  }, []);

  if (loading) return <p>Loading...</p>;

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: pageContent }} />
    </div>
  );
};

export default WordPressPage;
