import logo from './logo.svg';
import './App.css';
import { ApolloProvider } from '@apollo/client';
import client from './ApolloClient';
import Posts from '../src/Components/Post';
import WordPressPage from './Components/WordPressPage';

function App() {
  return (
    <ApolloProvider client={client}>
      <div className="App">
        {/* <Posts /> */}
        <WordPressPage/>
      </div>
    </ApolloProvider>
  );
}

export default App;
