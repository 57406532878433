// src/components/Posts.js
import React from 'react';
import { gql, useQuery } from '@apollo/client';

const GET_POSTS = gql`
  query GetPosts {
    posts {
      nodes {
        id
        title
        excerpt
        date
      }
    }
  }
`;

const Posts = () => {
    const { loading, error, data } = useQuery(GET_POSTS);
  
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
  
    return (
      <div>
        {data.posts.nodes.map((post) => (
          <div key={post.id}>
            <h2>{post.title}</h2>
            <p dangerouslySetInnerHTML={{ __html: post.excerpt }} />
            <p>{new Date(post.date).toLocaleDateString()}</p>
          </div>
        ))}
      </div>
    );
  };
  
  export default Posts;